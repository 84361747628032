.signatureMain {
  background-image: url("./../../../src/assets/images/hometop.png");
  width: calc(100vw);
  min-height: 1000px;
  background-size: cover;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}
.eclipse {
  width: 90.78px !important;
  height: 89.61px !important;
  background: #656AE5 !important;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 50% !important;
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
}

.eclipseSelected {
  width: 90.78px !important;
  height: 89.61px !important;
  background: #656AE5B5 !important;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important;
  border-radius: 50% !important;
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
}

.vector {
  width: 63px;
  height: 24px;
  margin: 0px 8px;
}

.signatureButton {
  text-transform: none !important;
  background: #656AE5 !important;
  margin-top: 30px !important;
  padding: 17px 41px !important;
  color: #FCFCFC !important;
  font-size: 18px !important;
}

.signatureButton:hover {
  text-transform: none !important;
  background: #656AE5 !important;
  margin-top: 30px !important;
  padding: 17px 41px !important;
  color: #FCFCFC !important;
  font-size: 18px !important;
}

.inputFile {
  width: 700px;
  height: 183px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 2;
  cursor: pointer;
}

.mainContainer {
  max-width: 1000px !important;
  background: #FFFEFF !important;
  margin-top: 90px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  box-shadow: 0px 1px 42px rgba(0, 0, 0, 0.1) !important;
  border-radius: 11px !important;
  padding-bottom: 36px !important;
}

@media (max-width: 1200px) {
  .textH {
    font-size: 35px !important;
    line-height: 52px !important;
    text-align: center !important;
    margin-top: 40px !important;
  }
  .eclipse {
    display: none !important;
    position: absolute !important;
  }
  .vector {
    display: none !important;
    position: absolute !important;
  }
  .mainContainer {
    max-width: 520px !important;
    margin-top: 46px !important;
    padding: 10px !important;
  }
  .block {
    max-width: 500px !important;
    margin-top: 0px !important;
    margin-bottom: -10px !important;
  }
  .inputFile {
    max-width: 500px !important;
    height: 128px !important;
    //width: 100% !important;
  }

  .select {
    font-size: 14px !important;
    line-height: 18px !important;
    padding: 0px 10px !important;
  }

  .selectLogo {
    width: 29.9px !important;
    height: 27.57px !important;
  }
  .signatureMain {
    padding: 8px !important;
    min-height: 600px !important;
  }
  .eclipseSelected {
    width: 94.48px !important;
    height: 88px !important;
    margin-top: -25px !important;
  }
  .signatureButton {
    padding: 17px 30px !important;
    font-size: 14px !important;
    margin-bottom: 20px !important;
  }

  .signatureButton:hover {
    padding: 17px 30px !important;
    font-size: 14px !important;
    margin-bottom: 20px !important;
  }

  .tranz {
    margin-top: 40px !important;
    font-size: 19px !important;
    line-height: 29px !important;
  }
  .nr {
    font-size: 16px !important;
    line-height: 22px !important;
  }
  .auth {
    font-size: 16px !important;
    line-height: 25px !important;
    margin-top: 40px !important;
  }
  .authGrid {
    margin-bottom: 30px !important;
  }
  .objName {
    font-size: 14px !important;
    line-height: 18px !important;
  }
  .downloadFiles {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}

@media (max-width: 800px) {
  .inputFile {
    width: 90vw !important;
  }
}
@media (max-width: 600px) {
  .inputFile {
    width: 90vw !important;
  }
}