.headerTypography {
  text-decoration: none !important;
  font-size: 16px !important;
}

.button {
  text-transform: none !important;
  background-color: #EAF5F9 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #161D39 !important;
}


.appBar {
  height: 105px !important;
  position: static !important;
}

.headerMob {
  display: none !important;
  padding-right: 3px !important;
}

.headerItems {
  padding: 8px !important;
  border-bottom: 1px solid #D3D3D3 !important;
  text-decoration: none !important;
  color: black !important;
}

@media (max-width: 1200px) {
  .headerPC {
    display: none !important;
    position: absolute !important;
  }
  .appBar {
    position: fixed !important;
    top: 0 !important;
    margin-bottom: 46px !important;
  }
  .headerMob{
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
  .appBar {
    height: 45px !important;
  }
  .button {
    text-transform: none !important;
    background-color: #EAF5F9 !important;
    font-weight: 500 !important;
    font-size: 11px !important;
    color: #161D39 !important;
  }
  .headerMenu {
    position: fixed !important;
    top: 44px !important;
    left: 0;
    height: 100%;
    background: #fffefe !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    transform: translateX(-100%) !important;
    transition: all 0.4s !important;
    z-index: 9999 !important;  }
  .headerMenuActive {
    position: fixed !important;
    top: 44px !important;
    left: 0;
    height: 100%;
    background: #fffefe !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    transform: translateX(0%) !important;
    transition: all 0.4s !important;
    z-index: 1000 !important;
  }
}

@media (max-width: 358px) {
  .headerNumber {
    display: none !important;
    position: absolute !important;
  }
}







